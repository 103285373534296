
import Vue from "vue";

export default Vue.extend({
  props: {
    type: {
      type: String,
      default: ""
    },
    button: {
      type: Object,
      default() {
        return {};
      }
    }
  },
  data() {
    return {
      showSecondaryArrowIfPossible: true
    };
  },
  computed: {
    buttonDisabled(): boolean {
      const { button } = this;
      return button.disabled || button.loading;
    },
    buttonClassName(): any {
      const { type } = this;
      if (type === "primary") {
        return this.primaryButtonClassName;
      }
      return this.secondaryButtonClassName;
    },
    primaryButtonClassName(): any {
      const { buttonIsDisabled } = this;
      const classes = [
        "flex",
        "items-center",
        "justify-center",
        "px-8",
        "py-2",
        "cursor-pointer",
        "rounded-sm",
        "font-semibold",
        "uppercase",
        "bg-green-500",
        "border",
        "border-green-600",
        "hover:bg-green-600",
        "hover:border-green-700",
        "hover:shadow-sm",
        "text-sm",
        "text-white",
        "select-none",
        "page-primary-button",
        "w-full",
        "text-center"
      ];

      if (buttonIsDisabled) {
        classes.push("cursor-not-allowed");
        classes.push("opacity-50");
      }
      return Object.fromEntries(classes.map((a, i) => [a, !!i]));
    },
    secondaryButtonClassName(): any {
      const { buttonIsDisabled } = this;
      const classes = [
        "flex",
        "items-center",
        "justify-center",
        "px-8",
        "py-2",
        "cursor-pointer",
        "rounded-sm",
        "font-semibold",
        "uppercase",
        "text-sm",
        "bg-opacity-60",
        "select-none",
        "page-secondary-button",
        "w-full",
        "text-center"
      ];

      if (buttonIsDisabled) {
        classes.push("cursor-not-allowed");
        classes.push("opacity-50");
      }
      return Object.fromEntries(classes.map((a, i) => [a, !!i]));
    },
    buttonIsDisabled(): boolean {
      const { loading, disabled } = this.button;
      return disabled || loading;
    }
  }
});
